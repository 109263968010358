@media only screen and (max-width: 600px) {
    .carro {
        max-width: 257%!important;
        margin-left: -21%;
        margin-top: -32%;
    
    }
    .tarjeta{
        margin-top: 185%;
        width: 248%;
        margin-left: -135%!important;
    }
    .precio{
        margin-left: -134%!important;
    }

    .suerte{
        margin-left: -126%!important;
    }
    .suerte2{
        width: 224%;
        margin-left: -51%;
    }
    .suerte3{
        width: 224%;
        margin-left: 52%;
    }
  }