@media only screen and (max-width: 600px) {
    .css-doq0dk {
        position: relative!important;
        width: 0%!important;
        height: 35%!important;
        margin-left: 46%;
    }

    .css-1qzevvg{
        margin-left: 33%!important;
        height: 7%!important;    
    }

    .espacio{
        MARGIN-BOTTOM: -85%!important;
    }
  }