.to{
    top: 48%;
    position: absolute;
    left:73%;
}
.letra{
    font-size: 15px;
}

.letraLink{
    font-size: 15px;
}
@media  only screen and (max-width:650px){
    .to{
        top:10%;
    }

}

@media  only screen and (max-width:1010px){
    .to{
      top:40%;
    }

}

@media  only screen and (max-width:1750px){
    .to{
        top:45%;
    }

}

@media  only screen and (max-width:1380px){
    .to{
        top:40%;
    }

}
@media  only screen and (max-width:995px){
    .to{
       margin-top: 5%;
    }
.letra{
    font-size: 12px;
}
}

@media  only screen and (max-width:770px){
    .to{
       margin-top: 10%;
    }
    .letra{
        font-size: 8px;
    }
}
@media  only screen and (max-width:550px){
    .to{
       margin-top: 13%;
    }

}
@media  only screen and (max-width:510px){
    .to{
       margin-top: 15%;
    }

}

@media  only screen and (max-width:468px){
    .to{
       margin-top: 17%;
    }

}

@media  only screen and (max-width:494px){
    .letra{
        font-size: 6px;
    }

}
@media  only screen and (max-width:395px){
    .to{
       margin-top: 19%;
    }

}

@media  only screen and (max-width:276px){
    .to{
       margin-top: 26%;
    }

}

