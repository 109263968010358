@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color1: #07A2BA;
  --color2: #0D606E;
  --color3: #3D3A3C;
}

.defaultTitle{
color: var(--color1);
}

.tab ul > li > a {
  background-color: red;
  color: white;
}
.to {
  top: 48%;
  position: absolute;
  left: 73%;
}

.letra {
  font-size: 15px;
}

.letraLink {
  font-size: 15px;
}

@media only screen and (max-width:650px) {
  .to {
    top: 10%;
  }

}

@media only screen and (max-width:1010px) {
  .to {
    top: 40%;
  }

}

@media only screen and (max-width:1750px) {
  .to {
    top: 45%;
  }

}

@media only screen and (max-width:1380px) {
  .to {
    top: 40%;
  }

}

@media only screen and (max-width:995px) {
  .to {
    margin-top: 5%;
  }

  .letra {
    font-size: 12px;
  }
}

@media only screen and (max-width:770px) {
  .to {
    margin-top: 10%;
  }

  .letra {
    font-size: 8px;
  }
}

@media only screen and (max-width:550px) {
  .to {
    margin-top: 13%;
  }

}

@media only screen and (max-width:510px) {
  .to {
    margin-top: 15%;
  }

}

@media only screen and (max-width:468px) {
  .to {
    margin-top: 17%;
  }

}

@media only screen and (max-width:494px) {
  .letra {
    font-size: 6px;
  }

}

@media only screen and (max-width:395px) {
  .to {
    margin-top: 19%;
  }

}

@media only screen and (max-width:276px) {
  .to {
    margin-top: 26%;
  }

}

/* ...Estilos de los contenedores....... */
.cuadro-estilo {
  padding: 20px;
  margin-bottom: 2%;
  border-radius: 15px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  margin-left: 9%;
  margin-right: 9%;
  background: #ffffff;
}

/* ...Estilos de los contenedores....... */

/*......Estilos carrusel rifas.....*/
.custom-prev-arrow {
  margin-left: 40% !important;
  bottom: 100% !important;
  background-color:  rgba(214, 213, 213, 0.3);
  border-width: 0;
  border-radius: 15px;
  width: 20%;
}

.custom-next-arrow{
  margin-left: 40% !important;
  top: 100% !important;
  background-color:  rgba(214, 213, 213, 0.3);
  border-width: 0;
  border-radius: 15px;
  width: 20%;
}


.card {
  height: 500px;
  margin-top: 2%;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardImgContainer {
  flex: 1 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 310px;
}

.cardImg {
  object-fit: contain;
  max-width: 100%;
  max-height: 430px;
}


.cardFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardContent {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.textCard {
  margin: 0px;
  margin-top: -11%;
}

/* Estilos para pantallas pequeñas */
@media (max-width: 768px) {
  .card {
    height: auto;
  }

  .cardImgContainer {
    height: auto;
    max-height: 200px;
  }

  .cardImg {
    width: 100%;
    max-height: 300px;
  }

  .custom-prev-arrow {
    margin-left: 40% !important;
    bottom: 100% !important;
  }
  
  .custom-next-arrow{
    margin-left: 40% !important;
    top: 100% !important;
  }
}

/*......Estilos carrusel rifas.....*/


/* ...Estilos selector de boletas....... */
.text-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}



.custom-search-bar {
  position: relative;
  display: flex;
  align-items: center;
}

.custom-search-bar button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 20%;
  margin: 0;
  background-color: rgb(7, 152, 157);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* Estilos específicos para pantallas pequeñas */
@media (max-width: 700px) {
  .custom-search-bar {
    position: relative;
    align-items: center;
    width: 120%;
    right: 10%;

  }

  .custom-search-bar button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 25%;
    margin: 0;
    background-color: rgb(7, 152, 157);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  
}


/* ...Estilos selector de boletas....... */

.info-box {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-align: center;
}

.thumbnail {
  transition: transform 0.3s ease-in-out;
}

.active-thumbnail {
  transform: scale(1.2);
  border: 2px solid #3b82f6;
}
.carousel-arrow {
  background-color: rgb(7, 152, 157);
  color: white;
  font-size: 25px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-arrow-prev {
  left: 10px;
}

.carousel-arrow-next {
  right: 10px;
}

/* .active-thumbnail {
  border: 2px solid rgb(7, 152, 157);
  transform: scale(1.1);
} */


.tarjeta{
    flex-Direction: column;
    margin-bottom: 7%;
    background-size: 100%;
    background-image: url(/static/media/tarjeta_movil.f3181c2b.png);
    background-Position-X: center;
    background-Repeat: no-repeat;
    width: 100%;
    border-Color: #0D606E;

}
.numero_tarjeta{
    margin-top: 37%;
}

.header{
    background-Color: #0D606E ;
    align-Self:center;
    width:100%;
}

.altura{
    width:100%;
    height:900;
    

}

.separar_campos{
    margin-top: 18px;
    margin-left: 70px;
    margin-bottom: 5%;
}
.digitos_tarjeta{
    margin-left: -4%;
}

.nombre{
    margin-top: -8%;
    margin-left: -5%;
}

.documento{
    margin-top: -9%;
}

.valido{
    color: white;
    margin-top: -13px;
    margin-bottom: 9%;

}
.letra{
    margin-top: -11%;
    margin-bottom: 16%;
}

.mm_aa{
    margin-left: 100px;
    margin-top: -16% !important;
    margin-bottom: 10%;
}
.csv{
    margin-top: -13%;
    margin-bottom: 14%;
}



@media only screen and (max-width: 600px) {
    .tarjeta {
        background-size: cover; /* Hace que la imagen cubra todo el contenedor */
        background-position: center; /* Centra la imagen en el contenedor */
        background-repeat: no-repeat; /* Evita que la imagen se repita */
        width: 150px; /* El contenedor ocupará todo el ancho disponible del modal */
        height: 300px; /* Dale una altura fija al contenedor, ajusta si es necesario */
        margin: 0 auto;
        margin-top: 0!important; /* Centra el contenedor en el modal */
        padding: 0;
        overflow: hidden;
    }

    .altura {
        padding: 0;
        margin: 0;
    }

    .digitos_tarjeta, .separar_campos, .nombre, .csv, .valido, .numero_tarjeta {
        margin: 0 auto;
        max-width: 100%;
    }

    .modal-content {
        max-width: 100%;
        width: 100%;
        overflow: hidden;
    }
}




/* Dispositivos extra pequeños (teléfonos, 600 px y menos) */
@media only screen and (min-width: 0px) and (max-width: 360px) {

}

/* Dispositivos pequeños (tabletas verticales y teléfonos grandes, 600 px y más) */
@media only screen and (min-width: 600px) {

}

/* Dispositivos medianos (tabletas horizontales, 768 px y más) */
@media only screen and (min-width: 768px) {

}

/* 
Dispositivos grandes (laptops/de escritorio, 992px y más) */
@media only screen and (min-width: 992px) {

}

/* Dispositivos extragrandes (portátiles y equipos de escritorio grandes, 1200 px y más) */
@media only screen and (min-width: 1200px) {

}
* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  padding: 50px 15px;
}
@media screen and (max-width: 700px), (max-height: 500px) {
  .wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;
}


@media screen and (max-width: 576px) {
  .card-form {
    margin: 0 auto;
  }
}
.card-form__inner {
  background: #fff;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  border-radius: 10px;
  padding: 35px;
  padding-top: 180px;
}
@media screen and (max-width: 480px) {
  .card-form__inner {
    padding: 25px;
    padding-top: 165px;
  }
}
@media screen and (max-width: 360px) {
  .card-form__inner {
    padding: 15px;
    padding-top: 165px;
  }
}
.card-form__row {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 480px) {
  .card-form__row {
    flex-wrap: wrap;
  }
}
.card-form__col {
  flex: auto;
  margin-right: 35px;
}
.card-form__col:last-child {
  margin-right: 0;
}
@media screen and (max-width: 480px) {
  .card-form__col {
    margin-right: 0;
    flex: unset;
    width: 100%;
    margin-bottom: 20px;
  }
  .card-form__col:last-child {
    margin-bottom: 0;
  }
}
.card-form__col.-cvv {
  max-width: 150px;
}
@media screen and (max-width: 480px) {
  .card-form__col.-cvv {
    max-width: initial;
  }
}
.card-form__group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.card-form__group .card-input__input {
  flex: 1 1;
  margin-right: 15px;
}
.card-form__group .card-input__input:last-child {
  margin-right: 0;
}
.card-form__button {
  width: 100%;
  height: 55px;
  background: #2364d2;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 500;
  font-family: "Source Sans Pro", sans-serif;
  box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-form__button {
    margin-top: 10px;
  }
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }
}
@media screen and (max-width: 360px) {
  .card-item {
    height: 180px;
  }
}
.card-item.-active .card-item__side.-front {
  transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
}
.card-item.-active .card-item__side.-back {
  transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
}
.card-item__focus {
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.65);
}
.card-item__focus:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #08142f;
  height: 100%;
  border-radius: 5px;
  filter: blur(25px);
  opacity: 0.5;
}
.card-item__focus.-active {
  opacity: 1;
}
.card-item__side {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
  transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
  transform-style: preserve-3d;
  transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  height: 100%;
}
.card-item__side.-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
  z-index: 2;
  padding: 0;
  height: 100%;
}
.card-item__side.-back .card-item__cover {
  transform: rotateY(-180deg);
}
.card-item__bg {
  max-width: 100%;
  display: block;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card-item__cover {
  height: 100%;
  background-color: #1c1d27;
  position: absolute;
  height: 100%;
  background-color: #1c1d27;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}
.card-item__cover:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 2, 29, 0.45);
}
.card-item__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 10px;
}
@media screen and (max-width: 480px) {
  .card-item__top {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__top {
    margin-bottom: 15px;
  }
}
.card-item__chip {
  width: 60px;
}
@media screen and (max-width: 480px) {
  .card-item__chip {
    width: 50px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__chip {
    width: 40px;
  }
}
.card-item__type {
  height: 45px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 100px;
  margin-left: auto;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item__type {
    height: 40px;
    max-width: 90px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__type {
    height: 30px;
  }
}

.card-item__typeImg {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.card-item__type.slide-fade-up .card-item__typeImg {
  opacity: 1;
  transform: translateY(0);
}

.card-item__info {
  color: #fff;
  width: 100%;
  max-width: calc(100% - 85px);
  padding: 0px 15px;
  font-weight: 500;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__info {
    padding: 10px;
  }
}
.card-item__holder {
  opacity: 0.7;
  font-size: 13px;
  margin-bottom: 6px;
}
@media screen and (max-width: 480px) {
  .card-item__holder {
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.card-item__wrapper {
  font-family: "Source Code Pro", monospace;
  padding: 25px 15px;
  position: relative;
  z-index: 4;
  height: 100%;
  text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
  -webkit-user-select: none;
          user-select: none;
}
@media screen and (max-width: 480px) {
  .card-item__wrapper {
    padding: 20px 10px;
  }
}
.card-item__name {
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
@media screen and (max-width: 480px) {
  .card-item__name {
    font-size: 16px;
  }
}
.card-item__nameItem {
  display: inline-block;
  min-width: 8px;
  position: relative;
}
.card-item__number {
  font-weight: 500;
  line-height: 1;
  color: #fff;
  font-size: 27px;
  margin-bottom: 35px;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__number {
    font-size: 21px;
    margin-bottom: 15px;
    padding: 10px 10px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__number {
    font-size: 19px;
    margin-bottom: 10px;
    padding: 10px 10px;
  }
}
.card-item__numberItem {
  width: 16px;
  display: inline-block;
}
.card-item__numberItem.-active {
  width: 30px;
}
@media screen and (max-width: 480px) {
  .card-item__numberItem {
    width: 13px;
  }
  .card-item__numberItem.-active {
    width: 16px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__numberItem {
    width: 12px;
  }
  .card-item__numberItem.-active {
    width: 8px;
  }
}
.card-item__content {
  color: #fff;
  display: flex;
  align-items: flex-start;
}
.card-item__date {
  flex-wrap: wrap;
  font-size: 18px;
  margin-left: auto;
  padding: 0px, 15px;
  display: inline-flex;
  width: 80px;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__date {
    font-size: 16px;
  }
}
.card-item__dateItem {
  position: relative;
}
.card-item__dateItem span {
  width: 22px;
  display: inline-block;
}
.card-item__dateTitle {
  opacity: 0.7;
  font-size: 13px;
  padding-bottom: 6px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item__dateTitle {
    font-size: 12px;
    padding-bottom: 5px;
  }
}
.card-item__band {
  background: rgba(0, 0, 19, 0.8);
  width: 100%;
  height: 50px;
  margin-top: 30px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 480px) {
  .card-item__band {
    margin-top: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__band {
    height: 40px;
    margin-top: 10px;
  }
}
.card-item__cvv {
  text-align: right;
  position: relative;
  z-index: 2;
  padding: 15px;
}
.card-item__cvv .card-item__type {
  opacity: 0.7;
}
@media screen and (max-width: 360px) {
  .card-item__cvv {
    padding: 10px 15px;
  }
}
.card-item__cvvTitle {
  padding-right: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
}
.card-item__cvvBand {
  height: 45px;
  background: #fff;
  margin-bottom: 30px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  color: #1a3b5d;
  font-size: 18px;
  border-radius: 4px;
  box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);
}
@media screen and (max-width: 480px) {
  .card-item__cvvBand {
    height: 40px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__cvvBand {
    margin-bottom: 15px;
  }
}

.card-list {
  margin-bottom: -130px;
}
@media screen and (max-width: 480px) {
  .card-list {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 20px;
}
.card-input__label {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #1a3b5d;
  width: 100%;
  display: block;
  -webkit-user-select: none;
          user-select: none;
}
.card-input__input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  font-family: "Source Sans Pro", sans-serif;
}
.card-input__input:hover, .card-input__input:focus {
  border-color: #3d9cff;
}
.card-input__input:focus {
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
}
.card-input__input.card-input__input--year {
  width: 150px; /* Ajusta el ancho para el año */
}
.card-input__input.-select {
  -webkit-appearance: none;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC");
  background-size: 12px;
  background-position: 90% center;
  background-repeat: no-repeat;
  padding-right: 30px;
}

.card-item__number.focused,
.card-item__info.focused,
.card-item__date.focused,
.card-item__type.focused {
    border: 2px solid #3d9cff; /* Ajusta el color y el grosor del borde según tu preferencia */
    padding: 0px; /* Asegúrate de que el borde no sobrescriba el contenido */
    border-radius: 5px; /* Opcional: para bordes redondeados */
    background-color: rgba(61, 156, 255, 0.1); /* Opcional: para un fondo sutil */
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}

.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}

.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}

.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}

.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}

.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}

.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}

.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 500px) {
  .github-btn:hover {
    transform: scale(1.1);
    box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
  }
}
@media screen and (max-width: 700px) {
  .github-btn {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;
  }
  .github-btn:active {
    transform: scale(1.1);
    box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
  }
}
@media only screen and (max-width: 600px) {
    .carro {
        max-width: 257%!important;
        margin-left: -21%;
        margin-top: -32%;
    
    }
    .tarjeta{
        margin-top: 185%;
        width: 248%;
        margin-left: -135%!important;
    }
    .precio{
        margin-left: -134%!important;
    }

    .suerte{
        margin-left: -126%!important;
    }
    .suerte2{
        width: 224%;
        margin-left: -51%;
    }
    .suerte3{
        width: 224%;
        margin-left: 52%;
    }
  }
@media only screen and (max-width: 768px) {
    .tabla{
        margin-bottom: 0.7% !important;
        
    } 
    .contenedor_tabla{
        margin-left: -3% !important;
        width: 105.5% !important;
    }

}


@media only screen and (max-width: 600px) {
    .css-doq0dk {
        position: relative!important;
        width: 0%!important;
        height: 35%!important;
        margin-left: 46%;
    }

    .css-1qzevvg{
        margin-left: 33%!important;
        height: 7%!important;    
    }

    .espacio{
        MARGIN-BOTTOM: -85%!important;
    }
  }
.to{
    top: 48%;
    position: absolute;
    left:73%;
}
.letra{
    font-size: 15px;
}

.letraLink{
    font-size: 15px;
}
@media  only screen and (max-width:650px){
    .to{
        top:10%;
    }

}

@media  only screen and (max-width:1010px){
    .to{
      top:40%;
    }

}

@media  only screen and (max-width:1750px){
    .to{
        top:45%;
    }

}

@media  only screen and (max-width:1380px){
    .to{
        top:40%;
    }

}
@media  only screen and (max-width:995px){
    .to{
       margin-top: 5%;
    }
.letra{
    font-size: 12px;
}
}

@media  only screen and (max-width:770px){
    .to{
       margin-top: 10%;
    }
    .letra{
        font-size: 8px;
    }
}
@media  only screen and (max-width:550px){
    .to{
       margin-top: 13%;
    }

}
@media  only screen and (max-width:510px){
    .to{
       margin-top: 15%;
    }

}

@media  only screen and (max-width:468px){
    .to{
       margin-top: 17%;
    }

}

@media  only screen and (max-width:494px){
    .letra{
        font-size: 6px;
    }

}
@media  only screen and (max-width:395px){
    .to{
       margin-top: 19%;
    }

}

@media  only screen and (max-width:276px){
    .to{
       margin-top: 26%;
    }

}


.tamaño{
    margin-left: 21%;
}
