@media only screen and (max-width: 768px) {
    .tabla{
        margin-bottom: 0.7% !important;
        
    } 
    .contenedor_tabla{
        margin-left: -3% !important;
        width: 105.5% !important;
    }

}

